import axios from "axios";
import React from "react";
import { navigate } from "gatsby";
import { useState } from "react";
import { blockedDomains, emailRegex, HUBSPOT_PROXY_URL } from "../constants";
import { toast, Toaster } from "react-hot-toast";
import "../styles/page/encord-competitor.scss";

const NewLeadFormWithAPI = ({
  pageName = "",
  location,
  portalId = "25381551",
  formId = "c90a5ae1-1852-43f5-9346-a57837b9f470",
  btnText = "Get Free Trial",
  isBtnColor,
  isMaxWidth,
}) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!error) {
      const [cookie] = document.cookie
        .split("; ")
        .filter((x) => x.includes("hubspotutk="));
      const [, hubspotutk] = cookie?.split("=") || ["", ""];
      setError("");
      try {
        if (emailRegex.test(email)) {
          setLoading(true);

          /**
         @summary Block verify api for temporary time
          const response = await axios.get(
          `https://verifymail.io/api/${email}?key=${VERIFY_EMAIL_API_KEY}`
        );
         */

          if (
            // response?.data?.deliverable_email &&
            // !response?.data?.catch_all &&
            // response?.data?.mx &&
            // !response?.data?.disposable
            true
          ) {
            const requestPayload = {
              fields: [
                {
                  objectTypeId: "0-1",
                  name: "email",
                  value: email,
                },
              ],
            };
            if (hubspotutk) {
              Object.assign(requestPayload, {
                context: {
                  hutk: hubspotutk,
                  pageUri: location?.href,
                  pageName: pageName,
                },
              });
            }
            const res = await axios.post(
              HUBSPOT_PROXY_URL,
              Object.assign(requestPayload, {
                portalId,
                formId,
              })
            );
            if (res?.data?.redirectUri) {
              navigate(res?.data?.redirectUri);
            }
            if (res?.data?.inlineMessage) {
              toast.success(
                <div
                  dangerouslySetInnerHTML={{ __html: res?.data?.inlineMessage }}
                />
              );
            }
          } else {
            setError("Please enter your work e-mail.");
          }
        } else {
          setError("Email must be formatted correctly.");
        }
      } catch (error) {
        if (
          error?.response?.data &&
          error?.response?.data.errors &&
          error?.response?.data.errors[0]?.message
        ) {
          const [, errorMessage] =
            error?.response?.data?.errors[0]?.message.split(". ") || ["", ""];
          if (errorMessage) {
            setError(errorMessage ?? error?.response?.data?.errors[0]?.message);
          }
        } else {
          setError("Please enter your work e-mail.");
        }
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <form
        id="hsForm_c90a5ae1-1852-43f5-9346-a57837b9f470"
        className="justify-center commercial-form hs-form-private hsForm_c90a5ae1-1852-43f5-9346-a57837b9f470 hs-form-c90a5ae1-1852-43f5-9346-a57837b9f470 hs-form-c90a5ae1-1852-43f5-9346-a57837b9f470_8b87ddee-9460-4f6d-92c0-a8785eede26d hs-form stacked"
        onSubmit={handleSubmit}
      >
        <div
          className={`${isMaxWidth ? "max-w-[466px]" : "max-w-[502px]"} w-full`}
        >
          <legend
            className="hs-field-desc"
            style={{ display: "none" }}
          ></legend>
          <div className="input">
            <input
              name="email"
              placeholder="Your work email"
              type="email"
              className="shadow-lg hs-input"
              required
              disabled={loading}
              onChange={(event) => {
                setEmail(event.target.value);
                setError("");
                const _filtered = blockedDomains.filter((x) =>
                  event.target.value.includes(x)
                );
                if (_filtered?.length) {
                  setError("Please enter your work email.");
                }
              }}
              value={email}
            />
          </div>
          {error && (
            <ul className="no-list hs-error-msgs inputs-list" role="alert">
              <li>
                <label
                  className={`hs-error-msg 
                  ${isBtnColor ? "text-[white]" : "text-[black]"}`}
                >
                  {error}
                </label>
              </li>
            </ul>
          )}
        </div>
        <div
          className={`hs_submit hs-submit lg:absolute 
          ${
            isMaxWidth && error
              ? "lg:bottom-[41px] md:right-[-133px] md:bottom-[85px]"
              : error
              ? "lg:bottom-[41px]"
              : "lg:bottom-[17px]"
          }
          relative ${
            isMaxWidth
              ? "lg:right-[120px] md:right-[-123px] md:bottom-[61px]"
              : "lg:right-[184px]"
          } right-[0px]  bottom-[0px]`}
        >
          <div className="hs-field-desc" style={{ display: "none" }}></div>
          <div className="actions">
            <button
              type="submit"
              disabled={loading || !!error}
              className="bg-purple-500 border-none shadow-lg hs-button primary large hover:bg-purple-400"
            >
              {loading ? (
                <div className="flex items-center">
                  Submitting
                  <div className="w-4 h-4 ml-2 border-2 border-white border-solid rounded-full animate-spin border-t-transparent"></div>
                </div>
              ) : (
                <>{btnText}</>
              )}
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default NewLeadFormWithAPI;
